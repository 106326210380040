/*
 * angular-confirm
 * http://schlogen.github.io/angular-confirm/
 * Version: 1.1.0 - 2015-14-07
 * License: Apache
 */
angular.module('angular-confirm', ['ui.bootstrap'])
  .controller('ConfirmModalController', ['$scope', '$modalInstance', 'data', function ($scope, $modalInstance, data) {
      $scope.data = angular.copy(data);

      $scope.ok = function () {
      $modalInstance.close();
    };

      $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

      $scope.other = function () {
        $modalInstance.close('other');
      };

      //Added By : Dishant Sakhida :: 2 Jul 2024 :: Bug#53312
      setTimeout(function () {
          var isFirefox = typeof InstallTrigger !== 'undefined';
          console.log('isFirefox:', isFirefox); // Check if Firefox is detected
          if (isFirefox) {

              var modalDialog = document.getElementById("confirm-box");
              if (modalDialog && isFirefox) {
                  modalDialog.parentElement.parentElement.style.position = 'fixed';
                  modalDialog.parentElement.parentElement.style.left = '50vh';
                  modalDialog.parentElement.parentElement.style.right = '50vh';
              }
          }
      }, 0);

  }])
  .value('$confirmModalDefaults', {
    template: '<div class="modal-header" id="confirm-box"><h3 class="modal-title">{{data.title}}</h3></div>' +
    '<div class="modal-body">{{data.text}}</div>' +
    '<div class="modal-footer">' +
    '<button class="btn btn-primary" id="iddataok" ng-click="ok()">{{data.ok}}</button>' +
    '<button class="btn btn-default" id="iddatahasother" ng-click="other()" ng-show="data.hasOther">{{data.other}}</button>' +
    '<button class="btn btn-default" id="iddatacancel" ng-click="cancel()">{{data.cancel}}</button>' +    
    '</div>',
    controller: 'ConfirmModalController',
    defaultLabels: {
      title: 'Confirm',
      ok: 'OK',
      cancel: 'Cancel',
      other:'Other',
      hasOther: false
    }
  })
  .factory('$confirm', ['$modal', '$confirmModalDefaults', function ($modal, $confirmModalDefaults) {
    return function (data, settings) {
      settings = angular.extend($confirmModalDefaults, (settings || {}));

      data = angular.extend({}, settings.defaultLabels, data || {});

      if ('templateUrl' in settings && 'template' in settings) {
        delete settings.template;
      }

      settings.resolve = {
        data: function () {
          return data;
        }
      };

      return $modal.open(settings).result;
    };
  }])
  .directive('confirm', ['$confirm', function ($confirm) {
    return {
      priority: 1,
      restrict: 'A',
      scope: {
        confirmIf: "=",
        ngClick: '&',
        confirm: '@',
        confirmSettings: "=",
        confirmTitle: '@',
        confirmOk: '@',
        confirmCancel: '@',
        confirmOther: '@'
      },
      link: function (scope, element, attrs) {


        element.unbind("click").bind("click", function ($event) {

          $event.preventDefault();

          if (angular.isUndefined(scope.confirmIf) || scope.confirmIf) {

            var data = {text: scope.confirm};
            if (scope.confirmTitle) {
              data.title = scope.confirmTitle;
            }
            if (scope.confirmOk) {
              data.ok = scope.confirmOk;
            }
            if (scope.confirmCancel) {
              data.cancel = scope.confirmCancel;
            }
            if (scope.confirmOther) {
                data.other = scope.confirmOther;
            }
            $confirm(data, scope.confirmSettings || {}).then(scope.ngClick);
          } else {

            scope.$apply(scope.ngClick);
          }
        });

      }
    }
  }]);
